/*
Create css styling for the customerTable component
*/

.customerTable {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/* Link's should be unstyled completely */
.customerTableLink {
  text-decoration: none;
  color: black;
  &:hover {
    opacity: 75%;
  }
}


