.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  z-index: 1000;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
}

