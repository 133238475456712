.navBar {
  height: 60px;
  width: 100%;
  max-height: 64px;
}
.navLink {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  margin: 0 1rem;
  padding: 0 1rem;
  border-right: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*the two icons should be displayed side by side*/


