/*Styling for the addContractorModal component
It should be a modal that pops up when the user clicks on the add contractor button in the contractor table
When the modal is visible the modal should be in front of the rest of the screen, and the rest of the screen should be darkened and blurred slightly. */

.addContractorModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.addContractorModalBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.addContractorModalBackground:hover {
  cursor: pointer;
}

.addContractorModalContent {
  z-index: 1;
  width: 60%;
  height: 60%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.addContractorModalTitle {
  font-size: 25px;
  font-weight: bold;
  color: #212529;
  text-align: center;
}

.addContractorModalBody {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.addContractorModalInput {
  width: 100%;
  height: 50px;
  border-radius: 5px;
}

.addContractorModalButton {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border: none;
}

.addContractorModalButton:hover {
  cursor: pointer;
}

.addContractorModalButton:disabled {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border: none;
  opacity: 0.5;
}

.addContractorModalButton:disabled:hover {
  cursor: not-allowed;
}

.addContractorModalCloseButton {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background-color: #dc3545;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border: none;
}

.addContractorModalCloseButton:hover {
  cursor: pointer;
}

.addContractorModalCloseButton:disabled {
  background-color: #dc3545;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border: none;
  opacity: 0.5;
}

.addContractorModalCloseButton:disabled:hover {
  cursor: not-allowed;
}

.addContractorModalCloseButton {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background-color: #dc3545;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border: none;
}

.addContractorModalCloseButton:hover {
  cursor: pointer;
}

.addContractorModalCloseButton:disabled {
  background-color: #dc3545;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border: none;
  opacity: 0.5;
}

.addContractorModalCloseButton:disabled:hover {
  cursor: not-allowed;
}
