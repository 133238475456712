/*

*/

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    height: 100%;
}
.home_header {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home_header_title {
  /*Centers the header title*/
  text-align: center;
  padding: 5vh;
}

.homeTitle {
  font-size: 3rem;
  font-weight: bold;
  color: #212529;
}

.homeBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.home_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 15px;
}

.home_content_left_title {
  font-size: 2rem;
  font-weight: bold;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.home_content_left_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.home_content_right_title {
  font-size: 2rem;
  font-weight: bold;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.home_content_right_body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.cardClass {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #cbcbcb;
}
