/*This is the styling file for the confirmDeletePopover component
It shoudld mimick the addUserPopover component, but with a different title and body
The popover should be a modal that pops up when the user clicks on the delete button in the charges table
When the modal is visible the modal should be in front of the rest of the screen, and the rest of the screen should be darkened and blurred slightly.

*/

.confirmDeletePopover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.confirmDeletePopoverBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.confirmDeletePopoverContent {
  z-index: 3;
  width: 50%;
  height: 50%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.confirmDeletePopoverCancel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
/* The confirm delte button that should be red, square with rounded corners */
.confirmDeletePopoverDelete {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
}
