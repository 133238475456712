/* This is the styling file for the addUserPopover 

The popover should be a modal that pops up when the user clicks on the add user button in the header.
When the modal is visible the modal should be in front of the rest of the screen, and the rest of the screen should be darkened and blurred slightly. 

The modal should have a close button in the top right corner, and a title in the top center.

The modal should have a light grey background, and a white content area.
*/

.addUserPopover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.addUserPopoverBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.addUserPopoverBackground:hover {
  cursor: pointer;
}

.addUserPopoverContent {
  z-index: 1;
  width: auto;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5vh;
}

.addUserPopoverTitle {
  font-size: 25px;
  font-weight: bold;
  color: #212529;
  text-align: center;
}



.addUserPopoverInput {
  width: 50%;
  height: 40px;
  border-radius: 5px;
  padding: auto;
  margin-bottom: 10px;
}

.addUserPopoverInput label {
  font-size: 15px;
  font-weight: bold;
  color: #212529;
  margin-bottom: 5px;
}

.addUserPopoverInput input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #ced4da;
}


/*The submit button should be a green button with white text and rounded corners.*/

.addUserPopoverSubmit {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: #28a745;
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  cursor: pointer;
}

.addUserPopoverCancel {
  position: static;
  color: black;
  font-weight: bold;
  cursor: pointer;
}
/*should place the cancel in the top left corner of the modal content*/
.addUserPopoverCancelPlacer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}


/*The submit button should have margin and padding and be dispayed in the center at the bottom*/
